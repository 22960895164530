@charset "UTF-8";
@font-face {
  font-family: 'FuturaWeb';
  src: url(../../assets/fonts/futuraBook.eot);
  src: url(../../assets/fonts/futuraBook.eot?#iefix) format("embedded-opentype"), url(../../assets/fonts/futuraBook.woff) format("woff"), url(../../assets/fonts/futuraBook.ttf) format("truetype"), url(../../assets/fonts/futuraBook.svg#FuturaWeb) format("svg");
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: 'FuturaWeb';
  src: url(../../assets/fonts/futuraBold.eot);
  src: url(../../assets/fonts/futuraBold.eot?#iefix) format("embedded-opentype"), url(../../assets/fonts/futuraBold.woff) format("woff"), url(../../assets/fonts/futuraBold.ttf) format("truetype"), url(../../assets/fonts/futuraBold.svg#FuturaWeb) format("svg");
  font-weight: bold;
  font-style: normal; }

/*===== FONT FAMILY =====*/
body {
  font-family: 'FuturaWeb'; }

.no-development-placeholder-info {
  text-align: center; }

.development .development-info h3,
.no-development-placeholder .no-development-placeholder-info h3 {
  font-family: arial; }

/*===== PESO =====*/
h2,
.navbar h1,
.development .development-info h3,
.no-development-placeholder .no-development-placeholder-info h3,
.development .phase h4 {
  font-weight: bold; }

/*===== ALINHAMENTO =====*/
.page-title {
  text-align: left; }

a.add-proposal,
a.load-more,
.search-input input[type=text] {
  text-align: center; }

@media screen and (max-width: 575px) {
  #search-input {
    text-align: left; }
  .nav-tabs .nav-link {
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
    font-size: 1.2em;
    color: #000; }
  .nav-tabs .nav-link.active {
    color: #878582; }
  p.status-text {
    font-size: 1.4em; } }

/*===== ESTILO =====*/
a.add-proposal,
a.load-more {
  text-decoration: none; }

/*===== TAMANHO =====*/
/** Altera o tamanho da fonte de referencia para telas com rosulçao até 768px*/
@media screen and (max-width: 768px) {
  html {
    font-size: 0.75em; } }

@media screen and (max-width: 575px) {
  html {
    font-size: 0.6em; } }

.development .phase h4 {
  font-size: 1em; }

.development .development-info h4 {
  font-size: 1.1em; }

.development .development-info p {
  font-size: 1.2em; }

.development-info h3,
.development-info p {
  text-transform: capitalize; }

a.add-proposal,
.search-input input[type=text] {
  font-size: 1.55em; }

.development .development-info h3,
.no-development-placeholder .no-development-placeholder-info h3 {
  font-size: 1.6em; }

.navbar h1 {
  font-size: 1.7em; }

h2 {
  font-size: 1.75em; }

.navbar-toggler svg {
  font-size: 2em; }

a.load-more {
  font-size: 2.5em; }

/*===== COR =====*/
.page-title,
.development .development-info p:nth-child(4) {
  color: #005ca9; }

.navbar-toggler svg,
.navbar h1 {
  color: #fff; }

a.add-proposal {
  color: #F39200; }

.form-control::placeholder {
  color: #9b9b9b; }

.development .development-info h4,
.development .development-info p:nth-child(3),
.development .phase .status {
  color: #888; }

a.load-more {
  color: #d8d8d8; }

.search-input i {
  color: #aaa; }

.search-input input[type=text]:focus + i {
  color: #0290ff; }

.text_login {
  color: #005ca9;
  font-weight: bold;
  font-size: 0.813em;
  text-align: center; }
