@charset "UTF-8";
html, body {
  height: 100%; }

body {
  background-color: #f5f6fA; }

input:focus::-webkit-input-placeholder {
  opacity: 0;
  -webkit-box-shadow: 0 0 0px 1000px whitesmoke inset; }

.page-title {
  margin-top: 30px;
  margin-bottom: 40px; }

.page-title h2 {
  border-bottom: solid 2px #e8e8e8;
  padding-bottom: 10px; }

.page-title-divider {
  border-bottom: solid 3px #0290ff;
  width: 135px;
  margin-top: -11px; }

.no-horizontal-padding {
  padding-left: 0;
  padding-right: 0; }

.navbar {
  height: 95px;
  background: linear-gradient(45deg, #30a4b1, #0254a2 73%);
  box-shadow: 0 0 5px; }

.navbar + div {
  margin-top: 125px; }

.navbar-toggler {
  border: none; }

.navbar-brand {
  width: 10%; }

.full-width-component {
  width: 100%;
  border-radius: 4px;
  border: 1px solid #e8e8e8; }

a.add-proposal {
  display: block;
  background-color: #fff;
  line-height: 93px;
  margin-bottom: 15px; }

a.add-proposal i {
  margin-right: 30px; }

.no-development-placeholder {
  min-height: 216px;
  background: transparent url(../../assets/images/bg-search-house.svg) no-repeat 102% bottom;
  background-size: 252px; }

.no-development-placeholder .no-development-placeholder-info {
  position: absolute;
  top: 50%;
  transform: translateY(-50%); }

.development, .no-development-placeholder {
  background-color: #fff;
  padding: 22px; }

.development .development-info p {
  margin-bottom: 0; }

.development .development-info p:nth-child(3) {
  margin-bottom: 16px; }

.development .phase img.phase-icon {
  width: 60px;
  height: 58px; }

.development .phase h4 {
  margin-top: 15px; }

.development .phase .status {
  margin-top: 15px;
  display: flex;
  justify-content: center; }

.development .phase .status .status-text {
  margin: 0;
  line-height: 18px; }

.development .phase .status .status-icon {
  width: 18px;
  height: 18px;
  margin-right: 5px; }

.development .phase:nth-child(3) {
  border: 2px solid #e8e8e8;
  border-top: none;
  border-bottom: none; }

a.load-more {
  display: block;
  width: 100%; }

.search-input input[type=text] {
  height: 95px;
  width: 100%;
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  outline: none;
  box-sizing: border-box;
  transition: .3s;
  margin-bottom: 15px; }

.search-input input[type=text]:focus {
  border-color: #0290ff;
  box-shadow: 0 0 8px 0 #0290ff;
  color: #0290ff; }

.search-input input[type=text] {
  padding-left: 100px; }

.search-input {
  position: relative; }

.search-input svg {
  position: relative;
  float: left;
  left: 0;
  top: 62px;
  margin: -130px 50px 0px 50px;
  transition: .3s;
  color: #878582; }

.search-input input[type=text]:focus + svg {
  color: #0290ff; }

.development-pending {
  background-color: rgba(245, 166, 35, 0.05);
  border-color: rgba(245, 166, 35, 0.4); }

.phase-pending {
  background: transparent url(../../assets/images/icon-alert.svg) no-repeat 80% top;
  background-size: 35px; }

#developments li {
  display: none; }

/* Propriedades específicas para a tela de login */
div#login {
  background-color: #013A6B;
  background-image: linear-gradient(-50deg, #1b7fa8 50%, #005ca9 50%);
  min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

div#login .form-login {
  background-color: white;
  border-radius: 7px;
  margin-bottom: 0px;
  padding: 15px; }

.form-login #brand {
  margin-bottom: 2.5em; }

.img-fluid-login {
  height: 21px;
  width: 91px; }

.text_login {
  margin-top: 1.875em; }

.user_login input[type=text] {
  height: 3.5em;
  width: 100%;
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  outline: none;
  box-sizing: border-box;
  transition: .3s;
  margin-bottom: 15px;
  background-color: #fafafa; }

.user_login input[type=text]:focus {
  text-decoration: underline;
  border-bottom: 3px solid orange; }

.user_login input {
  padding-left: 3.125em; }

.user_login {
  position: relative; }

.user_login svg {
  position: relative;
  float: left;
  left: 0;
  margin: -3.188em 0px 0px 1.375em;
  transition: .3s;
  color: #878582;
  background-color: #fafafa; }

.user_login input[type=text]:focus + svg {
  color: #0290ff; }

.user_login input[type=password] {
  height: 3.5em;
  width: 100%;
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  outline: none;
  box-sizing: border-box;
  transition: .3s;
  margin-bottom: 15px;
  background-color: #fafafa; }

.user_login input[type=password]:focus {
  text-decoration: underline;
  border-bottom: 3px solid orange; }

.user_login input[type=password]:focus + svg {
  color: #0290ff; }

.btn_login {
  width: 100%;
  color: white;
  background-color: #005aaa;
  border: none;
  height: 3.5em;
  border-radius: 7px; }

.forgot_key {
  margin-left: auto;
  margin-right: 15px;
  margin-bottom: 1em;
  font-size: 0.875em;
  color: #005ca9; }

.ultimo_texto {
  float: left;
  text-align: center;
  margin-top: 50px; }

.ultimo_texto a {
  font-size: 0.875em;
  color: #005ca9;
  line-height: 1em; }

button:focus {
  outline: 0; }

#show-password {
  margin: -3.7em 1em 0px 0em;
  position: relative !important;
  border: none !important;
  width: 2em;
  height: 2em;
  background-color: #fafafa;
  float: right;
  font-size: 0.971em; }

#change_icon {
  margin: 0em 0 0 -0.5em; }

/* Propriedades específicas para tablets */
@media screen and (max-width: 768px) {
  .navbar {
    height: 66px; }
  .navbar + div {
    margin-top: 75px; }
  .navbar h1 {
    margin-top: 0.7rem; }
  .navbar-brand {
    width: 10%; }
  .page-title {
    margin-bottom: 20px; }
  a.add-proposal {
    line-height: 50px;
    height: 50px; }
  .search-input input[type="text"] {
    height: 50px;
    padding-left: 50px; }
  .search-input svg {
    top: 35px;
    margin: -80px 20px; }
  .no-development-placeholder {
    min-height: 175px;
    background-size: 200px; }
  .development .phase img.phase-icon {
    width: 46px;
    height: 44px; }
  .descriptive-title {
    display: none; }
  .phase-pending {
    background-size: 18px;
    background-position-x: 98%; } }

/* Propriedades específicas para telefones */
@media screen and (max-width: 575px) {
  .navbar {
    height: 56px; }
  .navbar-brand {
    width: 50px; }
  .navbar + div {
    margin-top: 50px; }
  .page-title {
    margin-bottom: 5px; }
  .page-title-divider {
    width: 80px;
    margin-top: -8px; }
  .search-input svg {
    top: 30px;
    margin: -75px 15px !important; }
  #search-input {
    padding-left: 35px; }
  .no-development-placeholder .no-development-placeholder-info {
    left: 50%;
    transform: translate(-50%, -50%); }
  .no-development-placeholder {
    min-height: 178px; }
  .development-info {
    margin-bottom: 15px; }
  .development .phase:nth-child(3) {
    border: none; }
  .phase-pending {
    background-image: none; }
  .nav-tabs {
    border: none; }
  .nav-tabs a.nav-link {
    border-bottom: 2px solid #a0a0a0;
    margin: 0 -1px; }
  .nav-tabs a.nav-link.active {
    background-color: transparent;
    border: 2px solid #a0a0a0;
    border-bottom: none; }
  .status {
    margin-top: 15px; }
  img.status-icon {
    width: 24px;
    vertical-align: bottom;
    margin-right: 10px; }
  .alert-icon {
    width: 18px;
    float: right;
    margin-right: -14px;
    margin-top: -15px; }
  .user_login input {
    padding-left: 1.7em; }
  .user_login svg {
    margin: -2.4em 0px 0px 0.375em; }
  .user_login {
    font-size: 2.1em; }
  .user_login input[type=text] {
    height: 2.2em; }
  .user_login input[type=password] {
    height: 2.2em; }
  .forgot_key {
    font-size: 1.4em; }
  .ultimo_texto {
    font-size: 1.4em; }
  #show-password {
    margin: -2.8em 0.5em 0 0;
    height: 1.8em; } }
